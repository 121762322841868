<template>
  <div class="Portfolio_Project">
    <Navbar></Navbar>
    <div class="container"></div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
export default {
  name: "Portfolio_Project",
  components: {
    Navbar,
  },
};
</script>
